import * as React from 'react';
import { Layout } from '../components/Layout';
import { Title } from '../components/Title';
import { Seo } from '../components/Seo';

function IndexSite({ location }) {
    return (
        <Layout location={location}>
            <div className="container">
                <div className="success">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="250" height="250">
                        <path d="M25 2C12.31 2 2 12.31 2 25s10.31 23 23 23 23-10.31 23-23S37.69 2 25 2zm0 2c11.61 0 21 9.39 21 21s-9.39 21-21 21S4 36.61 4 25 13.39 4 25 4zm9.988 10.988a1 1 0 0 0-.816.451L23.97 30.477 16.68 23.71a1 1 0 1 0-1.36 1.467l8.996 8.347 11.512-16.964a1 1 0 0 0-.84-1.573z" />
                    </svg>
                    <Title className="h1" level="h2">
                        Спасибо за ваше обращение!
                    </Title>
                    <p>
                        Это автоматический ответ, чтобы вы знали, что мы получили ваше письмо.
                        <br />Я отвечу вам в скором времени.
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default IndexSite;

export function Head() {
    return (
        <Seo title="Спасибо за ваше обращение!">
            <meta name="robots" content="noindex" />
        </Seo>
    );
}
